<template>
  <div class="app-container">
    <el-button type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="充值金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.money }}
        </template>
      </el-table-column>
      <el-table-column label="赠送金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.money_award }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="充值金额">
          <el-input v-model="form.money" placeholder="请输入充值金额" />
        </el-form-item>
        <el-form-item label="赠送金额">
          <el-input v-model="form.money_award" placeholder="请输入赠送金额" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        money: 0,
        money_award: 0,
      },
      ids: [],
    };
  },
  created() {
    this.getList();
    this.getSendmanList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          money: 0,
          money_award: 0,
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/wallet/chargeSet",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleAdd() {
      this.dialogVisible = true
    },
    handleEdit(item) {
      this.form = {
          id: item.id,
          money: item.money,
          money_award: item.money_award,
      };
      this.dialogVisible = true;
    },
    handleDel(item) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/wallet/chargeSetDel",
          method: "post",
          data: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    saveData() {
      if (this.form.money === '' || this.form.money < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的充值金额"
        });
        return;
      }
      if (this.form.money_award === '' || this.form.money_award < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的赠送金额"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/wallet/chargeSetSave",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
